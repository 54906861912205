<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card :dark="$store.getters.getColorPalette().isDark">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search"
            single-line
            hide-details
            :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="worksapceData"
          item-key="param_id"
          :search="search"
          :loading="loading"
         :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
        >
        <template v-slot:top>
          <v-toolbar
            flat
            
          >
            <v-toolbar-title>{{$store.getters.getTextMap().param_view}}
</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>

            <v-spacer></v-spacer>
            <span>
              <v-icon  :color="$store.getters.getColorPalette().submitbtnColor" :dark="$store.getters.getColorPalette().isDark"  :style="{fontSize:'25px'}" @click="downloadCsv">mdi-download-circle</v-icon>
            </span>
           
            <v-icon :dark="$store.getters.getColorPalette().isDark"  @click="getData">mdi-refresh</v-icon>
            
          </v-toolbar>
              </template>
              
              
        </v-data-table>
      </v-card>
    </v-container>
    </template>
    
    <script>
    import axios from 'axios'
    import InfoAlert from '@/components/InfoAlert'
    
    export default {
        name:'WorkspaceMachineViewTable',
        components:{
            InfoAlert
        },
        mounted(){
            this.getData()
        },
        data(){
            return {
                worksapceData:[],
                search:'',
                info:'',
                showDismissibleAlert:false,
                loading:false,
                expanded: [],
                headers: [
              
              { text: 'Organisation ID', value: 'organisation_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Worksapce ID', value: 'workspace_id', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Machine Name', value: 'machine_name', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              {
                text: 'Hub ID',
                value: 'hub_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
              {
                text: 'Machine ID',
                align: 'start',
                filterable: false,
                value: 'machine_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
                
              },
              {
                text: 'Workspace Name',
                align: 'start',
                filterable: false,
                value: 'workspace_name',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
              },
              {
                text: 'Machine Data Source',
                align: 'start',
                filterable: false,
                value: 'machine_data_source',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
              },
              {
                text: 'Process ID',
                align: 'start',
                filterable: false,
                value: 'process_id',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
              },
              {
                text: 'Process Name',
                align: 'start',
                filterable: false,
                value: 'process_name',
                class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName 
              },
          
              
            ],
            }
        },
        computed:{
            
        },
        methods:{
            getData(){
              console.log('called');
                axios.get(this.$store.state.api + "getWorkspaceMachineViewParams",{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                  console.log(response);
                if (response.data.status == "success") {
                    this.worksapceData=response.data.data
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            },

            downloadCsv(){             
             let csvContent = 'hub_id,machine_data_source,machine_id,machine_name,organisation_id,process_id,process_name,workspace_id,workspace_name\n' + this.worksapceData.map((obj)=>`${obj.hub_id},${obj.machine_data_source},${obj.machine_id},${obj.machine_name},${obj.organisation_id},${obj.process_id},${obj.process_name},${obj.workspace_id},${obj.workspace_name}`).join('\n');
             const blob = new Blob([csvContent], {type:'text/csv'})
             const link = document.createElement('a');
             link.href = URL.createObjectURL(blob);
             link.download = 'WorkspaceMachineViewTableData.csv'
             document.body.appendChild(link);
             link.click()
             document.body.removeChild(link)
          }
        }
    }
    </script>
    <style scoped>
    .v-card__title{
      background-color:#bdbdbd00;
    }
    .theme--light.v-toolbar.v-sheet{
      background-color: #B2DFDB00;
    }
    .theme--light.v-data-table{
       background-color: #B2DFDB00;
    }
    </style>